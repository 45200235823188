<template>
  <div id="dashboard-page">
    <section class="title-dashboard">
      <div class="container">
        <h2 class="detail-title">Dashboard</h2>
      </div>
    </section>

    <section class="dashboard-filter">
      <div class="container">
        <div class="dashboard-item mb24">
          <div class="d-flex align-items-center">
            <div> Filter</div>
            <div class="mx-3">
              <select class="form-select" v-model="filter">
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="years">Years</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <router-link to="/room">
              <div class="dashboard-item mb24">
                <div class="db-item-title">TOTAL ROOM</div>
                <div class="db-item-count tosca">{{ total.room }}</div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-sm-6">
            <router-link to="/simulation">
              <div class="dashboard-item mb24">
                <div class="db-item-title">TOTAL SIMULATION</div>
                <div class="db-item-count yellow">{{ total.simulation }}</div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-sm-6">
            <router-link to="/simulation?status=3">
              <div class="dashboard-item mb24">
                <div class="db-item-title">TOTAL ON GOING SIMULATION</div>
                <div class="db-item-count orange">{{ total.on_going_simulation }}</div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-sm-6">
            <router-link to="/simulation?status=2">
              <div class="dashboard-item mb24">
                <div class="db-item-title">TOTAL WAITING FOR APPROVAL</div>
                <div class="db-item-count purple">{{ total.waiting_for_approval }}</div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-sm-6">
            <router-link to="/simulation?status=0">
              <div class="dashboard-item mb24">
                <div class="db-item-title">TOTAL WAITING TO START</div>
                <div class="db-item-count blue">{{ total.waiting_to_start }}</div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-sm-6">
            <router-link to="/simulation?status=4">
              <div class="dashboard-item mb24">
                <div class="db-item-title">TOTAL FINISHED SIMULATION</div>
                <div class="db-item-count green">{{ total.finished_simulation }}</div>
              </div>
            </router-link>
          </div>
        </div>

        <hr>
      </div>
    </section>

    <section class="db-items">
      <div class="container">
        <div class="row gx-5">
          <div class="col-lg-7">
            <div class="d-flex justify-content-between align-items-center mb24">
              <div class="detail-title">News</div>
              <router-link to="/news" class="link-underline">View All</router-link>
            </div>
            <div class="dashboard-item main-list mb30">
              <template v-for="(berita, index) in news">
                <div class="main-list-items" :key="index">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-sm-6">
                      <div class="news-img">
                        <figure>
                          <router-link :to="`/news/${berita.slug}`">
                            <img :src="berita.banner_url" class="img-fluid">
                          </router-link>
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                      <div class="db-news">
                        <router-link :to="`/news/${berita.slug}`" class="db-news-title">
                          {{ berita.title }}
                        </router-link>
                        <div class="db-news-date">{{ berita.date }}</div>
                        <p>{{ berita.description | short_description(150) }}</p>

                        <router-link :to="`/news/${berita.slug}`" class="link-service">
                          View Details &nbsp;<span class="icon-chevron-right"></span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="row" v-if="$store.getters.userType == 'master'">
                <hr>
                <div class="col-lg-6 offset-lg-3">
                  <router-link to="/news/manage/list" class="btn btn-orange w-100">Manage News</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="d-flex justify-content-between align-items-center mb24">
              <div class="detail-title">Crisis Scenario</div>
              <router-link to="/scenario" class="link-underline">View All</router-link>
            </div>
            <div class="dashboard-item main-list mb30">
              <template v-for="(scenario, index) in scenarios">
                <router-link :to="`/scenario/${scenario.slug}`" class="crisis-list-items" :key="index">
                  <div class="row">
                    <div class="col-lg-4 col-sm-4">
                      <div class="crisis-img">
                        <figure>
                          <img :src="scenario.banner_url" class="img-fluid">
                        </figure>
                      </div>
                    </div>
                    <div class="col-lg-8 col-sm-8">
                      <h6>{{ scenario.title }}</h6>
                      <p>{{ scenario.description | short_description(75) }}</p>

                      <div class="db-news-date mb-0">{{ scenario.date }}</div>
                    </div>
                  </div>
                </router-link>
              </template>
              <div class="row" v-if="$store.getters.userType == 'master'">
                <hr>
                <div class="col-lg-8 offset-lg-2">
                  <router-link to="/scenario/manage/list" class="btn btn-orange w-100">Manage Crisis Scenario</router-link>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center mb24">
              <div class="detail-title">Agenda</div>
              <router-link to="/agenda" class="link-underline">View All</router-link>
            </div>
            <div class="dashboard-item main-list">
              <template v-for="(agenda, index) in agendas">
                <div class="inner-agenda dashboard mb24" :key="index">
                  <div class="date-agenda">
                    {{ agenda.day }}
                    <span>{{ agenda.date }}</span>
                  </div>
                  <div class="agenda-text dashboard">
                    <h6>{{ agenda.title }}</h6>
                    {{ agenda.description | short_description(130) }}
                  </div>
                </div>
              </template>
              <div class="row" v-if="$store.getters.userType == 'master'">
                <hr>
                <div class="col-lg-8 offset-lg-2">
                  <router-link to="/agenda/manage/list" class="btn btn-orange w-100">Manage Agenda</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import cookie from "js-cookie";

export default {
  name: "Dashboard",
  data() {
    return {
      filter: "daily",
      total: {
        room: 0,
        simulation: 0,
        on_going_simulation: 0,
        waiting_for_approval: 0,
        waiting_to_start: 0,
        finished_simulation: 0,
      },
      news: [],
      scenarios: [],
      agendas: []
    }
  }, 
  watch: {
    filter() {
      this.getTotalRoom()
      this.getTotalSimulation()
      this.getTotalOngoingSimulation()
      this.getTotalWaitingForApproval() //
      this.getTotalWaitingToStart()
      this.getTotalFinishedSimulation()
    }
  },
  mounted() {
    this.getAvatar()
    this.getTotalRoom()
    this.getTotalSimulation()
    this.getTotalOngoingSimulation()
    this.getTotalWaitingForApproval() //
    this.getTotalWaitingToStart()
    this.getTotalFinishedSimulation()
    this.getNews()
    this.getScenario()
    this.getAgenda()
  },
  methods: {
    getAvatar() {
      this.$axios.get('/avatar')
        .then(response => {
          console.log(response)
          if (response.data.success) {
            this.$store.commit("SET_AVATAR", response.data.data.avatar)
            // save data to cookie
            cookie.set("avatar", { avatar: response.data.data.avatar }, { expires: 1 });
          }
        })
    },
    getTotalRoom() {
      this.$axios.get(`/master/dashboard/total_room?filter=${this.filter}`)
      .then(response => {
        this.total.room = response.data.data.total_room
      })
    },
    getTotalSimulation() {
      this.$axios.get(`/master/dashboard/total_simulation?filter=${this.filter}`)
      .then(response => {
        this.total.simulation = response.data.data.total_simulation
      })
    },
    getTotalOngoingSimulation() {
      this.$axios.get(`/master/dashboard/ongoing_simulation?filter=${this.filter}`)
      .then(response => {
        this.total.on_going_simulation = response.data.data.ongoing_simulation
      })
    },
    getTotalWaitingForApproval() {
      this.$axios.get(`/master/dashboard/waiting_approval?filter=${this.filter}`)
      .then(response => {
        this.total.waiting_for_approval = response.data.data.waiting_approval
      })
    },
    getTotalWaitingToStart() {
      this.$axios.get(`/master/dashboard/waiting_start?filter=${this.filter}`)
      .then(response => {
        this.total.waiting_to_start = response.data.data.waiting_start
      })
    },
    getTotalFinishedSimulation() {
      this.$axios.get(`/master/dashboard/finished?filter=${this.filter}`)
      .then(response => {
        this.total.finished_simulation = response.data.data.finished
      })
    },
    getNews() {
      this.$axios.get('/master/dashboard/news')
      .then(response => {
        this.news = response.data.data
      })
    },
    getScenario() {
      this.$axios.get('/master/dashboard/crisis')
      .then(response => {
        this.scenarios = response.data.data
      })
    },
    getAgenda() {
      this.$axios.get('/master/dashboard/agenda')
      .then(response => {
        this.agendas = response.data.data 
      })
    }
  }
}
</script>